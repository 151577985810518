<template>
  <div class="link-selector">
    <a v-if="fullPath" href="javascript:void(0)" :title="fullPath.pathText" class="link-selector__link">
      <el-tag :closable="!disabled" @close="clearLink">
        <div class="link-selector__tag-content">{{ fullPath.pathText }}</div>
      </el-tag>
    </a>
    <el-cascader v-else v-model="link_types" :options="options" :props="props" :show-all-levels="false" :disabled="disabled" :placeholder="$t('please_select_jump_link')" size="mini" @change="handleCascaderChange" />
    <en-shop-picker :show.sync="show_shop_picker" :buyer-domain="$$Domain['buyer_pc']" :request="$$Request" :limit="1" @confirm="handleShopPickerConfirm" />
    <en-goods-picker :lang="currentLang.code" :show.sync="show_goods_picker" :type="$$DecorType" :limit="1" @confirm="handleGoodsPickerConfirm" @close="show_goods_picker = false" />
    <en-page-picker :show.sync="show_page_picker" :request="$$Request" :domain="$$Domain" :limit="1" type="decor" @confirm="handlePagePickerConfirm" />
  </div>
</template>

<script>
import i18n from '@/lang/index'
import * as Rules from '@/utils/rules'
import * as API_Shop from '@/api/shop'
import * as API_Pages from '@/api/pages'
import { clientType } from '@/utils/decor-types'
import { mapGetters } from 'vuex'

export default {
  name: 'LinkSelector',
  props: {
    value: {
      type: [Object, String],
      required: true
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      link: '',
      link_types: '',
      options: [
        {
          label: i18n.t('micro_page'),
          value: i18n.t('micro_page'),
          children: [
            { label: i18n.t('micro_page'), value: 'mini-page' },
            { label: i18n.t('shop_home_page_a'), value: 'shop-home' },
            { label: i18n.t('personal_center'), value: 'user-center' }
          ]
        },
        {
          label: i18n.t('product'),
          value: i18n.t('product'),
          children: [
            { label: i18n.t('all_commodities'), value: 'goods' },
            { label: i18n.t('part_of_the_commodity'), value: 'part-goods' },
            { label: i18n.t('shopping_car'), value: 'cart' },
            { label: i18n.t('classification_a'), value: 'category' }
          ]
        },
        {
          label: i18n.t('marketing_tools'),
          value: i18n.t('marketing_tools'),
          children: [
            { label: i18n.t('coupon'), value: 'coupons' },
            { label: i18n.t('time-limited_panic_buying'), value: 'seckill' },
            { label: i18n.t('group_activities'), value: 'assemble', disabled: clientType === 'pc' }, // 仅移动端页面
            { label: i18n.t('integral_mall'), value: 'points-mall' }
          ]
        },
        {
          label: i18n.t('other_a'),
          value: i18n.t('other_a'),
          children: [
            { label: i18n.t('custom_link'), value: 'custom' },
            { label: i18n.t('applet_path'), value: 'mini-program', disabled: clientType === 'pc' }
          ]
        },
      ],
      props: { expandTrigger: 'hover' },
      shopInfo: '',
      show_shop_picker: false,
      show_goods_picker: false,
      show_page_picker: false,
      miniPageItem: '',
      shopItem: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'defaultValueChange'
    }
  },
  model: { event: 'update', prop: 'value' },
  mounted() {
    this.shopItem = this.$$ShopInfo
  },
  computed: {
    ...mapGetters(['currentLang']),
    fullPath() {
      if (!this.link || !this.link_types) return
      const option = this.options.filter(item => item.value === this.link_types[0])[0]
      const subOption = option.children.filter(item => item.value === this.link_types[1])[0]
      let path = [option, subOption]
      let pathText = `${option.label} | ${subOption.label}`
      if (subOption.value === 'mini-page') {
        pathText = ` ${i18n.t('micropages_|')} ${this.miniPageItem['page_name'] || ''}`
      }
      if (subOption.value === 'shop-home' && this.shopItem) {
        pathText = ` ${i18n.t('shop_home_|')} ${this.shopItem['shop_name']}`
      }
      if (subOption.value === 'custom') {
        pathText = ` ${i18n.t('outer_chain_|')} ${this.link.value}`
      }
      if (subOption.value === 'mini-program') {
        pathText = ` ${i18n.t('applet_path_|')} ${this.link.value}`
      }
      return { path, pathText }
    }
  },
  methods: {
    /**
     * 选择器值发生改变
     * @param item
     */
    async handleCascaderChange(item) {
      const link_type = item[item.length - 1]
      let link = { type: link_type, value: '' }
      switch (link_type) {
        case 'custom':
          link.value = await this.inputCustomLink(); break
        case 'mini-program':
          link.value = await this.inputMiniProgramLink(); break
        case 'mini-page':
          this.show_page_picker = true
          link.value = undefined
          break
        case 'shop-home':
          link.value = await this.getShopLink(); break
        case 'user-center':
          link.value = `member`; break
        case 'part-goods':
          this.show_goods_picker = true
          link.value = undefined
          break
        default:
          link.value = `${link_type}`
      }
      if (link.value === undefined) return this.clearLink()
      this.link = link
      this.$emit('update', this.link)
    },
    /**
     * 清空值
     */
    clearLink() {
      this.$nextTick(() => {
        this.link = ''
        this.link_types = ''
      })
      this.$emit('update', '')
    },
    /**
     * 输入自定义链接
     * @returns {Promise<string>}
     */
    async inputCustomLink() {
      try {
        const res = await this.$prompt(i18n.t('please_enter_a_custom_link'), i18n.t('prompt'), {
          confirmButtonText: i18n.t('determine'),
          cancelButtonText: i18n.t('cancel'),
          inputPlaceholder: 'https://',
          inputPattern: Rules.url,
          inputErrorMessage: i18n.t('incorrect_link_format！')
        })
        return res.value
      } catch (e) {
        this.clearLink()
      }
    },
    /**
     * 输入小程序路径
     * @returns {Promise<string>}
     */
    async inputMiniProgramLink() {
      try {
        const res = await this.$prompt(i18n.t('please_enter_applet_path'), i18n.t('prompt'), {
          confirmButtonText: i18n.t('determine'),
          cancelButtonText: i18n.t('cancel'),
          inputPlaceholder: i18n.t('please_enter_applet_path')
        })
        return res.value
      } catch (e) {
        this.clearLink()
      }
    },
    /**
     * 默认值发生改变
     * @param newVal
     */
    async defaultValueChange(newVal) {
      this.link = newVal
      if (!newVal) this.link_types = ''
      let option = ''
      let subOption = ''
      for (let i = 0; i < this.options.length; i++) {
        option = this.options[i]
        if (option.value === newVal.type) break
        if (option.children && option.children.length) {
          subOption = option.children.filter(item => item.value === newVal.type)[0]
          if (subOption) break
          option = ''
          subOption = ''
        }
      }
      option && (this.link_types = [option.value])
      subOption && (this.link_types = [option.value, subOption.value])
      if (newVal && newVal.type === 'shop-home' && !this.shopItem) {
        this.shopItem = await API_Shop.getShopDetail(newVal.value)
      }
      if (newVal && newVal.type === 'mini-page' && !this.miniPageItem) {
        this.miniPageItem = await API_Pages.getPageDetail(newVal.value, false)
      }
    },
    /**
     * 获取店铺链接
     * @returns {Promise<string>}
     */
    async getShopLink() {
      if (this.$$ShopInfo) {
        return this.$$ShopInfo['shop_id']
      } else {
        this.show_shop_picker = true
        return undefined
      }
    },
    /**
     * 店铺选择器确认
     * @param items
     */
    handleShopPickerConfirm(items) {
      if (!items.length) return
      this.shopItem = items[0]
      this.link = {
        type: 'shop-home',
        value: items[0]['shop_id']
      }
      this.$emit('update', this.link)
    },
    /**
     * 商品选择器确认
     * @param items
     */
    handleGoodsPickerConfirm(items) {
      if (!items.length) return
      this.link = {
        type: 'part-goods',
        value: items[0]['goods_id']
      }
      this.$emit('update', this.link)
    },
    /**
     * 页面选择器确认
     * @param items
     */
    handlePagePickerConfirm(items) {
      if (!items.length) return
      this.miniPageItem = items[0]
      this.link = {
        type: 'mini-page',
        value: items[0].id
      }
      this.$emit('update', this.link)
    }
  }
}
</script>

<style lang="scss" scoped>
.link-selector {
  position: relative;
  display: flex;
  width: 100%;

  .link-selector__link {
    display: inline-block;
    width: 100%;
  }

  /deep/ {
    .el-tag {
      display: flex;
      align-items: center;
      position: relative;
      color: #155bd4;
      border-color: #155bd4;
      border-radius: 2px;
      width: 100%;
      height: 28px;
      box-sizing: border-box;
      margin-right: 5px;
      overflow: hidden;

      .el-tag__close {
        position: absolute;
        top: auto;
        right: 5px;
        color: #155bd4;

        &:hover {
          color: #ffffff;
          background-color: #155bd4;
        }
      }

      .link-selector__tag-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;
        max-width: 208px;
      }
    }

    .el-cascader {
      width: 100%;
    }
  }
}
</style>
